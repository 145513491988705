export var navData = [{
  label: '招标信息',
  name: 'bidNotice',
  id: 1
}, {
  label: '评分标准',
  name: 'scoring',
  id: 2
}]

export var formData = {
  bidCode: '',
  bidEndDate: '',
  bidEndTime: '',
  bidEvaluationEndTime: '',
  bidEvaluationStartTime: '',
  bidExpertList: [
    {
      bidExpertMessageList: [
      ],
      deptCode: '',
      deptName: '',
      deptWeight: null
    }
  ],
  bidFileList: [
  ],
  bidMaterialMap: { title: [], data: [] },
  bidMaterialPriceMap: {},
  bidMinDesc: '',
  bidMinPrice: null,
  bidScoringList: [
  ],
  bidStartDate: '',
  bidStartTime: '',
  bidSupplierList: [
  ],
  businessScope: '',
  companyCode: '',
  createDate: '',
  createUserId: '',
  createUserName: '',
  evaluationBidCode: '',
  itemName: '',
  modifyDate: '',
  paymentDays: '',
  quoteList: [
    {}
  ],
  releaseEndTime: '',
  releaseStartTime: '',
  status: 0,
  titleList: []
}

export var dataFormat = {
  formDisabled: true,
  fieldShow: {
    bidEndDate1: false,
    businessScope: false,
    bidMinPrice: false,
    bidMinDesc: false
  }
}

export var quoteData = {
  bidId: '',
  bidMaterialQuoteVoList: [
  ],
  paymentDays: '',
  srmFileList: [
  ],
  supplierId: ''
}
